<template>
  <div class="magic-animation">
    <img :src="imgSrc" />
    <audio ref="audioTip" :src="audio" style="display: none"></audio>
  </div>
</template>

<script>
let magic8s = require("./images/magic-animate-8s.gif");
let magic5s = require("./images/magic-animate-5s.gif");
let magic3s = require("./images/magic-animate-3s.gif");
export default {
  name: "magic",
  props: {
    duration: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      audio: require("@/assets/video/magic.wav")
    };
  },
  computed: {
    imgSrc() {
      if (this.duration === 8) {
        return magic8s;
      }
      if (this.duration === 3) {
        return magic3s;
      }
      return magic5s;
    },
  },
  mounted() {
    this.$refs.audioTip.currentTime = 0;
    this.$refs.audioTip.play();
  }
};
</script>

<style scoped lang="scss">
.magic-animation {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99999;

  img {
    position: absolute;
    height: 100vh;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
